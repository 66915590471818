import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_BASE_URL;

const getVersions = (organization, boxId) => {
  return axios.get(`${API_URL}/organization/${organization}/box/${boxId}/version`, { headers: authHeader() });
};

const createVersion = (organization, name, data) => {
  return axios.post(`${API_URL}/organization/${organization}/box/${name}/version`, data, { headers: authHeader() });
};

//const getVersions = (organization, name) => {
//  return axios.get(`${API_URL}/organization/${organization}/box/${name}/version`, { headers: authHeader() });
//};

const getVersion = (organization, boxId, versionNumber) => {
  return axios.get(`${API_URL}/organization/${organization}/box/${boxId}/version/${versionNumber}`, { headers: authHeader() });
};

const updateVersion = (organization, boxId, versionNumber, data) => {
  return axios.put(`${API_URL}/organization/${organization}/box/${boxId}/version/${versionNumber}`, data, { headers: authHeader() });
};

const deleteVersion = (organization, boxId, versionNumber) => {
  return axios.delete(`${API_URL}/organization/${organization}/box/${boxId}/version/${versionNumber}`, { headers: authHeader() });
};


const VersionService = {
  getVersions,
  createVersion,
  getVersion,
  updateVersion,
  deleteVersion

};

export default VersionService;