import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_BASE_URL + '/auth/';
let GRAVATAR_API_URL = "";
let GRAVATAR_API_KEY = "";

const fetchGravatarConfig = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_BASE_URL + '/config/gravatar');
      if (response.data && response.data.gravatar) {
        GRAVATAR_API_URL = response.data.gravatar.base_url.value;
        GRAVATAR_API_KEY = response.data.gravatar.api_key.value;
      }
      resolve();
    } catch (error) {
      console.error("Error fetching Gravatar configuration:", error);
      resolve();
    }
  });
};

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

const register = (username, email, password, invitationToken) => {
  return axios.post(API_URL + "signup", {
    username,
    email,
    password,
    invitationToken,
  });
};

const validateInvitationToken = (token) => {
  return axios.get(`${API_URL}validate-invitation/${token}`);
};


const login = (username, password) => {
  return axios
    .post(API_URL + "signin", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};

const refreshUserData = async () => {
  try {
    const response = await axios.get(process.env.REACT_APP_API_BASE_URL + "/user", { headers: authHeader() });
    if (response.data) {
      localStorage.setItem("user", JSON.stringify(response.data));
      return response.data;
    }
  } catch (error) {
    console.error("Error refreshing user data:", error);
  }
  return null;
};

const logout = () => {
  localStorage.removeItem("user");
  window.location.href = "/";
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const getGravatarProfile = async (emailHash) => {
  try {
    await fetchGravatarConfig();

    const response = await axios.get(`${GRAVATAR_API_URL}${emailHash}`, {
      headers: {
        Authorization: `Bearer ${GRAVATAR_API_KEY}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching Gravatar profile:", error);
    return null;
  }
};

const resendVerificationMail = () => {
  return axios.post(API_URL + "resend-verification", {}, { headers: authHeader() });
};

const verifyMail = (token) => {
  return axios.get(`${API_URL}verify-mail/${token}`);
};

const sendInvitation = (email, organizationName) => {
  return axios.post(API_URL + "invite", { email, organizationName }, { headers: authHeader() });
};

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  getGravatarProfile,
  refreshUserData,
  resendVerificationMail,
  verifyMail,
  sendInvitation,
  validateInvitationToken,
  fetchGravatarConfig
};

export default AuthService;
