import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_BASE_URL + '/invitations/';

const getActiveInvitations = (organizationId) => {
  return axios.get(API_URL + `active/${organizationId}`, { headers: authHeader() });
};

const deleteInvitation = (invitationId) => {
  return axios.delete(API_URL + `${invitationId}`, { headers: authHeader() });
};

const InvitationService = {
  getActiveInvitations,
  deleteInvitation,
};

export default InvitationService;