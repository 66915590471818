// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.btn-dark {
  --bs-btn-border-color: #807769;
}

.list {
  text-align: left;
  max-width: 90vw;
  margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA;EACE,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,YAAY;AACd","sourcesContent":["\n.btn-dark {\n  --bs-btn-border-color: #807769;\n}\n\n.list {\n  text-align: left;\n  max-width: 90vw;\n  margin: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
