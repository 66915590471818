import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_BASE_URL + '/config/';

const getConfig = (configName) => {
  return axios.get(API_URL + configName , { headers: authHeader() });
};
  
const updateConfig = (configName, configData) => {
  return axios.put(API_URL + configName ,  configData, { headers: authHeader() });
};

const ConfigService = {
  getConfig,
  updateConfig,
};

export default ConfigService;