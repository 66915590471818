import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_BASE_URL + '/service-accounts/';

const createServiceAccount = (description, expirationDays) => {
  return axios.post(API_URL, { description, expirationDays }, { headers: authHeader() });
};

const getServiceAccounts = () => {
  return axios.get(API_URL, { headers: authHeader() });
};

const deleteServiceAccount = (id) => {
  return axios.delete(API_URL + id, { headers: authHeader() });
};

const ServiceAccountService = {
  createServiceAccount,
  getServiceAccounts,
  deleteServiceAccount
};

export default ServiceAccountService;